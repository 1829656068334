import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTelegram } from '../../hooks/useTelegram';
import {
  MainButton,
  MainButtonProps, useThemeParams,
} from '@vkruglikov/react-telegram-web-app';
import axios from "axios";
import {useParams} from "react-router-dom";


const SaveButton: FC<{
  initialValues?: Partial<MainButtonProps> & { show?: boolean };
}> = ({ initialValues }) => {
  // @ts-ignore
  const coordinates = useSelector((state) => state.coordinates);
  const {notification_id} = useParams();
  const {tg} = useTelegram();
  const [progress, setProgress] = useState(false);
  const [buttonState, setButtonState] = useState<
    {
      show: boolean;
    } & Pick<MainButtonProps, 'disabled' | 'color' | 'onClick'>
  >({
    text: 'Finish',
    show: true,
    ...initialValues,
  });
  const onFinish = (values: any) => setButtonState(values);

  const handleClick = async () => {
    setProgress(true);
    console.log(coordinates);
    // const url = `${process.env.REACT_APP_BACKEND_API_URL}/api/notification/${notification_id}`;
    const url = `/api/notification/${notification_id}`;
    try {
      await axios.patch(url, coordinates);
      setProgress(false);
      tg.close();
    } catch (err) {
        setProgress(false);
        console.error('Error occurred:', err);
    }
  }

  return (
    <div>{buttonState?.show && <MainButton onClick={handleClick} progress={progress} color={buttonState.color} {...buttonState} />}</div>
  );
};

export default SaveButton;
