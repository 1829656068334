import './App.css';
import MapComponent from './components/Map/Map';
import SaveButton from './components/SaveButton/SaveButton';
import {Provider} from "react-redux";
import {BrowserRouter, Route, Routes} from "react-router-dom"
import store from './redux/store';
import Popup from "./components/Popup/Popup";

function App() {
  return (
      <Provider store={store}>
        <Popup />
        <BrowserRouter>
          <Routes>
            <Route path="/notification/:notification_id" element={<MapComponent />} />
          </Routes>
        </BrowserRouter>
        <BrowserRouter>
          <Routes>
            <Route path="/notification/:notification_id" element={<SaveButton />} />
          </Routes>
        </BrowserRouter>
      </Provider>
  );
}

export default App;
