import { configureStore } from '@reduxjs/toolkit';
import coordinatesSlice from './coordinatesSlice';

const store = configureStore({
  reducer: {
    coordinates: coordinatesSlice
  },
});

export default store;
