import React, {useEffect, useRef, useState} from 'react';
import mapboxgl from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import * as turf from "@turf/turf";
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import './Map.css';
import {useTelegram} from '../../hooks/useTelegram';
import {useParams} from "react-router-dom";
import axios from 'axios';
import {useDispatch, useSelector} from 'react-redux';
import {setCoordinates} from '../../redux/coordinatesSlice';

const MapComponent = () => {
    const {notification_id} = useParams();
    const mapContainerRef = useRef(null);
    const mapRef = useRef(null);
    const {tg, user, onClose} = useTelegram();
    const [isLoading, setIsLoading] = useState(false);
    const [map, setMap] = useState(null);
    const [draw, setDraw] = useState(null);
    const [initialCoordinates, setInitialCoordinates] = useState(null);
    const [clickCounter, setClickCounter] = useState(0);
    // const [polygon, setPolygon] = useState(null);
    const dispatch = useDispatch();
    const coordinates = useSelector((state) => state.coordinates);

    const handlePolygonChanges = (coords) => {
        const newCoordinates = coords;
        console.log(newCoordinates);
        dispatch(setCoordinates(newCoordinates));
    };

    // Init Map
    useEffect(() => {
        const initMap = () => {

            if (tg) {
                tg.ready();
                tg.expand();
            } else {
                console.error("Telegram Web App SDK not found.");
            }

            mapboxgl.accessToken = `${process.env.REACT_APP_MAPBOX_API_KEY}`;
            const mapInstance = new mapboxgl.Map({
                container: mapContainerRef.current,
                style: 'mapbox://styles/mapbox/streets-v11',
                center: [33.0386, 34.6841],
                zoom: 12,
            });

            const drawInstance = new MapboxDraw({
                displayControlsDefault: false,
                controls: {
                    polygon: true,
                    trash: true,
                },
                styles: [
                    {
                        "id": "gl-draw-polygon-fill",
                        "type": "fill",
                        "filter": ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
                        "paint": {
                            "fill-color": "#24A1DE",
                            "fill-outline-color": "#24A1DE",
                            "fill-opacity": 0.3
                        }
                    },
                    // polygon mid points
                    {
                        'id': 'gl-draw-polygon-midpoint',
                        'type': 'circle',
                        'filter': ['all',
                            ['==', '$type', 'Point'],
                            ['==', 'meta', 'midpoint']],
                        'paint': {
                            'circle-radius': 3,
                            'circle-color': '#24A1DE'
                        }
                    },
                    // polygon outline stroke
                    // This doesn't style the first edge of the polygon, which uses the line stroke styling instead
                    {
                        "id": "gl-draw-polygon-stroke-active",
                        "type": "line",
                        "filter": ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
                        "layout": {
                            "line-cap": "round",
                            "line-join": "round"
                        },
                        "paint": {
                            "line-color": "#24A1DE",
                            "line-dasharray": [0.2, 2],
                            "line-width": 2
                        }
                    },
                    // vertex point halos
                    {
                        "id": "gl-draw-polygon-and-line-vertex-halo-active",
                        "type": "circle",
                        "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
                        "paint": {
                            "circle-radius": 10,
                            "circle-color": "#FFF"
                        }
                    },
                    // vertex points
                    {
                        "id": "gl-draw-polygon-and-line-vertex-active",
                        "type": "circle",
                        "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ['!=', 'mode', 'static']],
                        "paint": {
                            "circle-radius": 8,
                            "circle-color": "#24A1DE",
                        },
                    },

                    // INACTIVE (static, already drawn)
                    // line stroke
                    {
                        "id": "gl-draw-line-static",
                        "type": "line",
                        "filter": ["all", ["==", "$type", "LineString"], ["==", "mode", "static"]],
                        "layout": {
                            "line-cap": "round",
                            "line-join": "round"
                        },
                        "paint": {
                            "line-color": "#000",
                            "line-width": 3
                        }
                    },
                    // polygon fill
                    {
                        "id": "gl-draw-polygon-fill-static",
                        "type": "fill",
                        "filter": ["all", ["==", "$type", "Polygon"], ["==", "mode", "static"]],
                        "paint": {
                            "fill-color": "#000",
                            "fill-outline-color": "#000",
                            "fill-opacity": 0.1
                        }
                    },
                    // polygon outline
                    {
                        "id": "gl-draw-polygon-stroke-static",
                        "type": "line",
                        "filter": ["all", ["==", "$type", "Polygon"], ["==", "mode", "static"]],
                        "layout": {
                            "line-cap": "round",
                            "line-join": "round"
                        },
                        "paint": {
                            "line-color": "#000",
                            "line-width": 3
                        }
                    }
                ],
                defaultMode: 'draw_polygon'
            });

            mapInstance.addControl(drawInstance);

            setMap(mapInstance);
            setDraw(drawInstance);
        }

        if (!map) {
            initMap();
        }

        return () => {
            if (map) {
                map.remove();
            }
        };
    }, [map]);


    useEffect(() => {
        const fetchCoordinates = async () => {
            if (isLoading) return;
            setIsLoading(true);
            // const url = `${process.env.REACT_APP_BACKEND_API_URL}/api/notification/${notification_id}`;
            const url = `/api/notification/${notification_id}`;
            const headers = {'ngrok-skip-browser-warning': true};
            const response = await axios.get(url, {headers: headers});
            const data = await response.data;
            console.log(data.coordinates);
            if (coordinates.coordinates !== data.coordinates) {
                dispatch(setCoordinates(data.coordinates));
            }
            setIsLoading(false);
        }
        fetchCoordinates();
    }, []);

    // Render Custom Area if already exists in DB
    useEffect(() => {
        if (!map || coordinates.coordinates.length < 4) return;
        const polygon = turf.polygon([coordinates.coordinates], {}, {"id": "polygon"});
        const polygonId = draw.add(polygon);
        map.flyTo({
            center: turf.centerOfMass(polygon).geometry.coordinates,
        });
        draw.changeMode('simple_select', {featureIds: [polygonId[0]]});
    }, [map, coordinates])


    useEffect(() => {
        if(!map) return;
        let startTime = Date.now();
        const onStart = (e) => {
            startTime = Date.now();
        };

        const onEnd = (e) => {
            const stopTime = Date.now();
            if (stopTime - startTime > 200) return;
            const drawMode = draw.getMode();
            if(drawMode !== 'draw_polygon') return;
            setClickCounter(clickCounter+1);
            if (clickCounter > 0) return;
            setInitialCoordinates([e.lngLat.lng, e.lngLat.lat]);
        };

        const onClick = (e) => {
            const drawMode = draw.getMode();
            if(drawMode !== 'draw_polygon') return;
            setClickCounter(clickCounter+1);
            if (clickCounter > 0) return;
            setInitialCoordinates([e.lngLat.lng, e.lngLat.lat]);
        };

        map.on('click', onClick);
        map.on('touchstart', onStart);
        map.on('touchend', onEnd);

        return () => {
            map.off('click', onClick);
            map.off('touchstart', onStart);
            map.off('touchend', onEnd);
        };
    }, [map, initialCoordinates, clickCounter]);


    useEffect(() => {
        if (!map || clickCounter !== 3) return;
        const circleRadius = 10;
        map.addSource('initial-point', {
            'type': 'geojson',
            'data': {
                "type": "FeatureCollection",
                "features": [{
                    "type": "Feature",
                    'properties': {
                        'desc': 'Save',
                        // 'icon': 'finish'
                    },
                    "geometry": {
                        "type": "Point",
                        "coordinates": initialCoordinates
                    }
                }]
            }
        });

        map.addLayer({
            'id': 'done-circle',
            'type': 'circle',
            'source': 'initial-point',
            layout: {},
            paint: {
                'circle-radius': 18,
                'circle-color': '#fff',
                'circle-stroke-width': 3,
                'circle-stroke-color': '#3eab2b',
            }
        });

        map.addLayer({
            'id': 'done-label',
            'type': 'symbol',
            'source': 'initial-point',
            layout: {
                'text-field': ['get', 'desc'],
                'text-font': ['Open Sans Bold'],
                'text-size': 12
            },
            paint: {
                'text-color': 'rgba(35,26,26,0.94)',
            }
        });
    }, [map, initialCoordinates, clickCounter]);

    useEffect(() => {
        if (!map) return;
        // Change mode to draw only one area and delete when try to draw another one
        map.on('draw.modechange', (e) => {
            const data = draw.getAll();
            const drawMode = draw.getMode();
            const layer = map.getLayer('done-circle');
            if (layer) {
                map.removeLayer('done-circle');
                map.removeLayer('done-label');
                map.removeSource('initial-point');
            }
            if (drawMode === 'draw_polygon') {
                const pids = [];
                const lid = data.features[data.features.length - 1].id;

                data.features.forEach((f) => {
                    if (f.geometry.type === 'Polygon' && f.id !== lid) {
                        pids.push(f.id);
                    }
                })
                draw.delete(pids);
                setInitialCoordinates(null);
                setClickCounter(0);
            }
        });

        map.on('draw.create', (e) => {
            const drawMode = draw.getMode();
            switch (drawMode) {
                case 'draw_point':
                    break;
                case 'draw_polygon':
                    const polygon = e.features[0];
                    const center = turf.centerOfMass(polygon).geometry.coordinates;
                    map.setCenter(center);
                    const layer = map.getLayer('done-circle');
                    if (layer) {
                        map.removeLayer('done-circle');
                        map.removeLayer('done-label');
                        map.removeSource('initial-point');
                    }
                    handlePolygonChanges(polygon.geometry.coordinates);
                    console.log('Updated polygon:', polygon);
                    break;
                case 'draw_line_string':
                    break;
                default:
                    break;
            }

        });

        map.on('draw.update', (e) => {
            const polygon = e.features[0];
            handlePolygonChanges(polygon.geometry.coordinates);
            // const url = `http://localhost:8000/api/notification/${notification_id}`;
            // axios.patch(url, {"coordinates": polygonCoordinates}).then((response) => {
            //     console.log(response.data);
            // });
            console.log('Updated polygon:', polygon);
        });

        map.on('draw.delete', (e) => {
            if (!e.features) return;
            draw.delete(e.features[0].id);
            console.log(draw.getMode())
            draw.changeMode('draw_polygon');
            setClickCounter(0);
            setInitialCoordinates(null);
        });

    }, [map, initialCoordinates]);

    return (<div>
        <div className="map-container">
            <div ref={mapContainerRef} className="map"></div>
        </div>
    </div>);
};

export default MapComponent;
