import { createSlice } from '@reduxjs/toolkit';

export const coordinatesSlice = createSlice({
  name: 'coordinates',
  initialState: {
    coordinates: [],
  },
  reducers: {
    setCoordinates: (state, action) => {
      state.coordinates = action.payload;
    },
  },
});

export const { setCoordinates } = coordinatesSlice.actions;
export default coordinatesSlice.reducer;
